import type {DartsThrow, DartsThrowWithRemaining} from '~/store/localGameStore'
import type {Throw} from '~/types'

export function convertApiThrowsToLocal(throws: Throw[]): DartsThrow[] {
    return throws.map((throwItem: Throw) => {
        return {
            participant_id: throwItem.tpid,
            player_id: throwItem.pid,
            leg: throwItem.leg,
            score: throwItem.s,
            darts_used: throwItem.du,
            finishing_throw: throwItem.ft,
            ulid: throwItem.ulid,
            failed: false
        }
    })
}

export function calculateAverage(dartsThrows: DartsThrow[], playerParticipantId: number | string): number {
    let totalScore = 0
    let totalDartsUsed = 0

    for (const dartsThrow of dartsThrows) {
        if (dartsThrow.participant_id === playerParticipantId) {
            totalScore += dartsThrow.score
            totalDartsUsed += dartsThrow.darts_used
        }
    }

    if (totalDartsUsed === 0) return 0 // Avoid division by zero

    return Number(((totalScore / totalDartsUsed) * 3).toFixed(2))
}

export function getMostThrowsInLeg(
    leg: number,
    throws: DartsThrow[],
    participant1Id: number | string,
    participant2Id: number | string,
): number[] {
    const homeThrows = getLegThrowsForParticipant(participant1Id, throws, leg, 0)
    const visitorThrows = getLegThrowsForParticipant(participant2Id, throws, leg, 0)

    let maxThrows = 0
    if (homeThrows.length >= visitorThrows.length) {
        maxThrows = homeThrows.length
    } else {
        maxThrows = visitorThrows.length
    }

    return Array.from({length: maxThrows}, (v, k) => k + 1)
}

export function getLegThrowsForParticipant(participantId: number | string, throws: DartsThrow[], leg: number, startingScreen: number): DartsThrowWithRemaining[] {
    const throwList = throws.filter((throwItem) => throwItem.participant_id === participantId && throwItem.leg === leg)

    let remaining = startingScreen

    return throwList.map((throwItem: DartsThrow) => {
        remaining = remaining - throwItem.score
        return {
            ...throwItem,
            remaining
        }
    })
}

export function getCurrentLeg(throws: DartsThrow[]): number {
    const legs = getLegs(throws)
    return legs.length === 0 ? 1 : legs[legs.length - 1]
}

export function getLegs(throws: DartsThrow[]): number[] {
    const legs: number[] = []
    throws.forEach((throwItem: DartsThrow) => {
        if (!legs.includes(throwItem.leg)) {
            legs.push(throwItem.leg)
        }
    })
    return legs
}

export function getWinningThrowForLeg(dartsThrows: Array<DartsThrow>, leg: number): undefined | DartsThrow {
    return dartsThrows.find((throwItem) =>
        throwItem.leg === leg && throwItem.finishing_throw
    )
}
